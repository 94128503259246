// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aba-tsx": () => import("./../../../src/pages/aba.tsx" /* webpackChunkName: "component---src-pages-aba-tsx" */),
  "component---src-pages-acolhimento-tsx": () => import("./../../../src/pages/acolhimento.tsx" /* webpackChunkName: "component---src-pages-acolhimento-tsx" */),
  "component---src-pages-apostila-tsx": () => import("./../../../src/pages/apostila.tsx" /* webpackChunkName: "component---src-pages-apostila-tsx" */),
  "component---src-pages-avaliacao-tsx": () => import("./../../../src/pages/avaliacao.tsx" /* webpackChunkName: "component---src-pages-avaliacao-tsx" */),
  "component---src-pages-cursos-tsx": () => import("./../../../src/pages/cursos.tsx" /* webpackChunkName: "component---src-pages-cursos-tsx" */),
  "component---src-pages-downloads-tsx": () => import("./../../../src/pages/downloads.tsx" /* webpackChunkName: "component---src-pages-downloads-tsx" */),
  "component---src-pages-fale-conosco-tsx": () => import("./../../../src/pages/fale-conosco.tsx" /* webpackChunkName: "component---src-pages-fale-conosco-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-psicoterapia-tsx": () => import("./../../../src/pages/psicoterapia.tsx" /* webpackChunkName: "component---src-pages-psicoterapia-tsx" */),
  "component---src-pages-supervisao-tsx": () => import("./../../../src/pages/supervisao.tsx" /* webpackChunkName: "component---src-pages-supervisao-tsx" */),
  "component---src-pages-telegram-tsx": () => import("./../../../src/pages/telegram.tsx" /* webpackChunkName: "component---src-pages-telegram-tsx" */),
  "component---src-pages-treinamento-parental-tsx": () => import("./../../../src/pages/treinamento-parental.tsx" /* webpackChunkName: "component---src-pages-treinamento-parental-tsx" */)
}

